<template>
  <section class="pb-2">
    <b-card>
      <template #header>
        <div class="d-flex align-items-center">
          <span class="text-primary">Newsletter </span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>
            <b-breadcrumb-item active> Crea template auto</b-breadcrumb-item>
            <b-breadcrumb-item active> Scegli auto</b-breadcrumb-item>
            <b-breadcrumb-item active v-if="step >= 2">
              Seleziona Opzioni</b-breadcrumb-item
            >
            <b-breadcrumb-item active v-if="step >= 3">
              Anteprima Template</b-breadcrumb-item
            >
            <b-breadcrumb-item active v-if="step >= 4">
              Template Creato</b-breadcrumb-item
            >
          </b-breadcrumb>
        </div>
      </template>

      <div class="mt-2">
        <div v-if="step == 1">
          <b-table
            :fields="fields"
            :items="vehicles"
            :per-page="15"
            :current-page="currentPage"
            :busy="loading"
            striped
            show-empty
            responsive
          >
            <template #table-busy>
              <div class="text-center text-primary-light my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>Caricamento dati delle auto...</strong>
              </div>
            </template>
            <template #head(selected)>
              <span></span>
            </template>
            <template #cell(selected)="data">
              <b-form-checkbox-group v-model="selected_auto">
                <b-form-checkbox :unchecked-value="null" :value="data.item" />
              </b-form-checkbox-group>
            </template>
            <template #cell(anno)="data">
              {{ data.item.immatricolazione_anno }}
            </template>
            <template #cell(km)="data">
              {{ data.item.chilometraggio }}
            </template>
          </b-table>

          <b-pagination
            v-model="currentPage"
            :total-rows="vehicles.length"
            :per-page="15"
            align="right"
            size="sm"
            class="mt-2"
          />
        </div>

        <!-- Seleziona Opzioni -->

        <div v-if="step == 2">
          <div class="my-4">
            <h3 class="text-primary-light">
              Pannello nuovo template da selezione auto
            </h3>

            <b-row>
              <b-col md="6">
                <b-form-group class="my-2" label="Titolo template">
                  <b-form-input
                    placeholder="Inserisci qui il nome del template..."
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </div>

          <div class="my-4">
            <h3 class="text-primary-light">Seleziona prezzo da visualizzare</h3>

            <b-form-radio-group class="my-2">
              <b-form-radio> Prezzo pubblico </b-form-radio>

              <b-form-radio>Prezzo rivenditore</b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="my-4">
            <h3 class="text-primary-light">Come vuoi visualizzarle</h3>

            <div class="d-flex">
              <div class="cursor-pointer p-2">
                <img src="@/assets/images/grid_view.svg" />
                <p class="mt-2">Griglia</p>
              </div>
              <div class="cursor-pointer ml-2 p-2 border border-primary-light">
                <img src="@/assets/images/list_view.svg" />
                <p class="mt-2">Lista</p>
              </div>
            </div>
          </div>
        </div>

        <!-- Anteprima Template -->

        <div v-if="step == 3">
          <h3 class="text-primary-light">Anteprima Template</h3>

          <p>In allestimento</p>
        </div>

        <!-- Template Completato -->

        <div v-if="step == 4">
          <div class="d-flex align-items-center justify-content-center my-5">
            <img src="@/assets/images/auto_confirm.svg" />

            <div class="ml-5">
              <h4 class="text-primary-light">Template salvato con successo</h4>

              <p>
                Il tuo template è stato salvato con <br />
                successo con le impostazioni che hai <br />
                selezionato precedentemente.
              </p>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-between" v-if="step < 4">
          <div>
            <div class="d-flex justify-content-between align-items-center">
              <p>Auto selezionate:</p>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <p>Tot. prezzo pubblico:</p>
            </div>
            <p>Tot. prezzo rivenditore:</p>
          </div>

          <div>
            <b-button
              v-if="step > 1"
              variant="outline-primary"
              class="mr-2"
              @click="step--"
              >Torna indietro</b-button
            >
            <b-button variant="primary" @click="step++"> Prosegui </b-button>
          </div>
        </div>
      </div>
    </b-card>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
  BTable,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormRadio,
  BFormCheckboxGroup,
  BFormCheckbox,
  BPagination,
  BSpinner,
  VBTooltip,
} from "bootstrap-vue";

import { Requests } from "@/api";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BTable,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BPagination,
    BSpinner,
  },

  directives: {
    "b-tooltip": VBTooltip,
  },

  data: () => ({
    fields: [
      {
        key: "selected",
        sortable: true,
      },
      {
        key: "marca",
        sortable: true,
      },
      {
        key: "modello",
        sortable: true,
      },
      {
        key: "versione",
        sortable: true,
      },
      {
        key: "anno",
        sortable: true,
      },
      {
        key: "km",
        sortable: true,
      },
      {
        key: "targa",
        sortable: true,
      },
      {
        key: "telaio",
        sortable: true,
      },
      {
        key: "colore",
        sortable: true,
      },
      {
        key: "prezzo_pubblico",
        sortable: true,
      },
      {
        key: "prezzo_rivenditore",
        sortable: true,
      },
    ],

    vehicles: [],
    selected_auto: [],

    step: 1,
    currentPage: 1,
    loading: false,
  }),

  created() {
    this.getAutoOnSale();
  },

  methods: {
    async getAutoOnSale() {
      this.loading = true;

      try {
        const response = await Requests.getAutoOnSale();
        this.vehicles = response;
      } catch (err) {
        console.debug(err);
      }

      this.loading = false;
    },
  },
};
</script>
